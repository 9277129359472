import { addMinutes } from 'date-fns';
/**
 * @param date
 *
 * Convert date to same date but in UTC
 * e.g. 02/08/2022 12:15pm GMT-0800 will becomes 02/08/2022 12:15pm UTC, no timezone conversion is involved
 */
export function convertTimeToUtcLiterally(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
}
/**
 *
 * @param date
 * The opposite of convertTimeToUtcLiterally
 * e.g. 02/08/2022 12:15pm UTC becomes 02/08/2022 12:15pm GMT-0800
 */
export function convertUtcToLocalLiterally(date) {
    return addMinutes(date, new Date().getTimezoneOffset());
}
export function formatDateTime(date) {
    return date.toLocaleString();
}
export function formatTime(date) {
    return date.toLocaleTimeString(undefined, { timeStyle: 'short' });
}
export function formatDate(date) {
    return date.toLocaleDateString();
}
