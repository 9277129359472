import bs58 from 'bs58';
export const isValidPublicKey = (pubkey) => {
    try {
        return bs58.decode(pubkey).length === 32;
    }
    catch {
        return false;
    }
};
export const isValidSignature = (sig) => {
    try {
        return bs58.decode(sig).length === 64;
    }
    catch {
        return false;
    }
};
export const detectInvalidPubKeys = (keys) => {
    const invalidKeys = [];
    for (const key of keys) {
        if (!isValidPublicKey(key)) {
            invalidKeys.push(key);
        }
    }
    return invalidKeys;
};
export const toBase58 = (u8) => {
    return bs58.encode(u8);
};
export const VALID_SOLANA_ADDRESS_REGEX = /^(|[a-zA-Z0-9]{32,44})$/;
