import { coerce, date, define, refine, is, size, string, } from 'superstruct';
import slug from './validation/slug';
import { isValidPublicKey } from './validation';
// eslint-disable-next-line
// @ts-ignore
import isIsoDate from 'is-iso-date';
/**
 * Superstruct validation
 */
export const positive = (val) => refine(val, 'positive', value => value >= 0);
export const gtZero = (val) => refine(val, 'positive', value => value > 0);
export const zeroOrPositive = (val) => refine(val, 'positive', value => value >= 0);
export const notEmpty = (val) => refine(val, 'notEmpty', value => value.length > 0);
export const integer = (val) => refine(val, 'integer', value => (value | 0) === value);
/**
 * Take date or string and convert it to a string in ISO date format
 */
export const isodateString = () => coerce(string(), date(), val => {
    if (val.toISOString) {
        return val.toISOString();
    }
    return val;
});
/**
 * ensure that string has ISO date format without coecrion to Date object
 */
export const isodate = (val) => refine(val, 'isodate', isIsoDate);
export const address = (val) => refine(val, 'address', isValidPublicKey);
const ALPHA_NUM = /^[a-zA-Z0-9]+$/;
export const collectionSymbol = size(slug(), 3, 64);
export const collectionName = size(string(), 3, 128);
export const profileHandler = size(string(), 4, 108);
export const discordInviteCode = define('discordInviteCode', (val) => {
    if (!is(val, size(string(), 3, Infinity))) {
        return 'Invite code should be at least 3 chars long';
    }
    if (!ALPHA_NUM.test(val)) {
        return 'Please enter the part of your discord invite after “https://discord.gg/”';
    }
    return true;
});
/**
 * Helper functions
 */
export const extendLinksFullPath = (links) => {
    const transformed = {};
    for (const resourceName in links) {
        const path = links[resourceName];
        if (!path) {
            continue;
        }
        switch (resourceName) {
            case 'twitter':
                transformed[resourceName] = `https://twitter.com/${path}`;
                break;
            case 'discord':
                transformed[resourceName] = `https://discord.gg/${path}`;
                break;
            case 'telegram':
                transformed[resourceName] = `https://t.me/${path}`;
                break;
            default:
                transformed[resourceName] = path;
        }
    }
    return transformed;
};
/**
 * Type guards
 */
export const isRecord = (value) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
export * from './cleanDeep';
export * from './httpClient';
export * from './time';
export * from './image';
export * from './web3';
export * from './url';
export * from './validation';
