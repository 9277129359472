import { PublicKey } from '@solana/web3.js';
import { METADATA_PROGRAM_ID, TOKEN_PROGRAM_ID, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, METAPLEX_ID, BPF_UPGRADE_LOADER_ID, SYSTEM, MEMO_ID, VAULT_ID, AUCTION_ID, } from '../metaplex/Constants';
let STORE;
export const programIds = () => {
    return {
        token: TOKEN_PROGRAM_ID,
        associatedToken: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
        bpf_upgrade_loader: BPF_UPGRADE_LOADER_ID,
        system: SYSTEM,
        metadata: METADATA_PROGRAM_ID,
        memo: MEMO_ID,
        vault: VAULT_ID,
        auction: AUCTION_ID,
        metaplex: METAPLEX_ID,
        store: STORE,
    };
};
export const findProgramAddress = async (seeds, programId) => {
    const result = await PublicKey.findProgramAddress(seeds, programId);
    return [result[0].toBase58(), result[1]];
};
