import { define } from 'superstruct';
const SEPARATOR = '_';
const VALID_CHARS = /[a-zA-Z0-9_]/;
const charsetRegex = new RegExp(VALID_CHARS).source;
const slugRegExp = new RegExp(`^${charsetRegex}(?:${SEPARATOR}?${charsetRegex})*$`);
const ERROR_MESSAGE = 'Should start with A-Z, a-z, 0-9 and can contain `_`';
const isSlug = (val) => {
    if (typeof val !== 'string') {
        return ERROR_MESSAGE;
    }
    return slugRegExp.test(val) || ERROR_MESSAGE;
};
export default () => define('slug', isSlug);
