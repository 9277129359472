export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["INELIGIBLE"] = "ineligible";
    VerificationStatus["PENDING"] = "pending";
    VerificationStatus["WATCH"] = "watch";
    VerificationStatus["APPROVED"] = "approved";
    VerificationStatus["REJECTED"] = "rejected";
})(VerificationStatus || (VerificationStatus = {}));
export var VerificationRejectionReasons;
(function (VerificationRejectionReasons) {
    VerificationRejectionReasons["INCORRECT_SOCIALS"] = "incorrect_socials";
    VerificationRejectionReasons["LOW_SOCIAL_ENGAGEMENT"] = "low_social_engagement";
    VerificationRejectionReasons["SPAMMING_APPLICATIONS"] = "spamming_applications";
    VerificationRejectionReasons["TEAM_DISCRETION"] = "team_discretion";
    VerificationRejectionReasons["BLACKLIST"] = "blacklist";
})(VerificationRejectionReasons || (VerificationRejectionReasons = {}));
export const RejectionReasonsDisplays = {
    [VerificationRejectionReasons.INCORRECT_SOCIALS]: 'Incorrect or personal socials',
    [VerificationRejectionReasons.LOW_SOCIAL_ENGAGEMENT]: 'Low social engagement',
    [VerificationRejectionReasons.SPAMMING_APPLICATIONS]: 'Spamming Applications',
    [VerificationRejectionReasons.TEAM_DISCRETION]: 'Team / Community Discretion',
    [VerificationRejectionReasons.BLACKLIST]: 'Silent / Blacklist',
};
export var VerificationEligibilities;
(function (VerificationEligibilities) {
    VerificationEligibilities["APPLY"] = "apply";
    VerificationEligibilities["PENDING"] = "pending";
    VerificationEligibilities["INELIGIBLE"] = "ineligible";
    VerificationEligibilities["APPROVED"] = "approved";
    VerificationEligibilities["REJECTED"] = "rejected";
    VerificationEligibilities["REJECTED_REAPPLY"] = "rejected_reapply";
})(VerificationEligibilities || (VerificationEligibilities = {}));
export function formatRejectionDisplay(rejectionReason, additionalNote, compact = true) {
    let displayStr = '';
    if (rejectionReason) {
        displayStr = `${compact ? 'Rejected: ' : 'Review result: Rejected - '} ${RejectionReasonsDisplays[rejectionReason]}`;
    }
    else {
        displayStr = compact ? 'Rejected' : 'Review result: Rejected';
    }
    if (additionalNote) {
        displayStr += ` (${additionalNote})`;
    }
    return displayStr;
}
