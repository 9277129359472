import * as v from 'superstruct';
import { ApplyPurposes, CollectionCategories } from '../creators';
import { collectionName, collectionSymbol, isodateString, notEmpty, zeroOrPositive, } from '../util';
import { BlacklistAccountType } from './blacklist';
export const CollectionCreateSchema = v.object({
    name: collectionName,
    symbol: collectionSymbol,
    description: v.size(v.string(), 10, 1000),
    image: v.string(),
    nftImageType: v.optional(v.enums(['jpeg', 'gif', 'mp4'])),
    website: v.optional(v.string()),
    discord: v.optional(v.string()),
    twitter: v.optional(v.string()),
    categories: v.array(v.string()),
    sortBy: v.optional(v.string()),
    isDraft: v.boolean(),
    totalItems: v.number(),
});
export const CollectionUpdateSchema = v.object({
    // update fields
    isVerified: v.defaulted(v.boolean(), false),
    nftImageType: v.optional(v.enums(['jpeg', 'gif', 'mp4'])),
    enabledAttributesFilters: v.defaulted(v.boolean(), false),
    enabledVersionFilter: v.defaulted(v.boolean(), false),
    enabledTotalSupply: v.defaulted(v.boolean(), false),
    enabledUniqueOwners: v.defaulted(v.boolean(), false),
    rarity: v.optional(v.object({
        showMoonrank: v.boolean(),
        showHowrare: v.boolean(),
        showMagicEden: v.boolean(),
    })),
    iframe: v.optional(v.string()),
    blockedMints: v.defaulted(v.string(), '[]'),
    blackListAttributes: v.defaulted(v.string(), '[]'),
});
export const CollectionDraftUpdateSchema = v.object({
    // update flags
    shouldUpdateCollection: v.boolean(),
    // update fields
    name: collectionName,
    symbol: collectionSymbol,
    description: v.size(v.string(), 10, 1000),
    assets: v.object({
        profileImage: v.string(),
    }),
    isVerified: v.optional(v.boolean()),
    derivative: v.nullable(v.optional(v.object({
        originalLink: notEmpty(v.string()),
        originalName: v.size(v.string(), 3, 128),
    }))),
    categories: v.object({
        primary: v.enums(Object.values(CollectionCategories)),
        secondary: v.nullable(v.optional(v.enums(Object.values(CollectionCategories)))),
    }),
    links: v.object({
        twitter: v.optional(v.string()),
        discord: v.optional(v.string()),
        website: v.optional(v.string()),
        mint: v.optional(v.string()),
    }),
    permission: v.optional(v.enums(['author', 'licensed'])),
    applyPurpose: v.enums(Object.values(ApplyPurposes)),
    totalSupply: zeroOrPositive(v.integer()),
    // mintDate: v.optional(v.nullable(isodate(v.string()))),
    mintDate: v.optional(v.nullable(isodateString())),
    // TODO: find a way to validate form textarea import with array[] struct
    mint: v.defaulted(v.string(), '[]'),
    candyMachineIds: v.defaulted(v.string(), '[]'),
    rarity: v.optional(v.object({
        showMoonrank: v.boolean(),
        showHowrare: v.boolean(),
        showMagicEden: v.boolean(),
    })),
    creatorTipsAddress: v.nullable(v.optional(v.string())),
});
export const CollectionDraftGradeSchema = v.object({
    id: v.string(),
    reviewMsg: v.string(),
    type: v.enums(['reject', 'approve', 'list']),
    collectionDraftPayload: v.object({
        source: v.array(v.string()),
    }),
    // last fetched version of the draft
    // used to prevent any actions on a stale draft data
    version: v.number(),
});
export const BlacklistCreateSchema = v.object({
    accountType: v.enums(Object.values(BlacklistAccountType)),
    value: v.string(),
});
export const UserPresaleUpdateSchema = v.object({
    enable: v.boolean(),
});
