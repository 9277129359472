// size in px
export const ImageSize = Object.freeze({
    tiny: '50',
    extraSmall: '100',
    small: '250',
    medium: '400',
    large: '640',
});
const CDN_BASE_URL = 'https://img-cdn.magiceden.dev';
export const buildCdnUrl = (url, opts) => {
    const rsOpts = opts.rs ?? 'fill';
    const keepNftstoragOpts = opts.keepNftstorageBaseUrl ?? false;
    const sizeX = ImageSize[opts.sizeX];
    const sizeY = ImageSize[opts.sizeY ?? opts.sizeX];
    if (url.includes(CDN_BASE_URL)) {
        // If url is already a CDN url, don't build again
        return url;
    }
    // only encode if there are query params
    const escaped = url.includes('?') ? encodeURIComponent(url) : url;
    let cdnUrl = `${CDN_BASE_URL}/rs:${rsOpts}:${sizeX}:${sizeY}:0:0/plain/${keepNftstoragOpts
        ? escaped
        : escaped.replace('nftstorage.link', 'dweb.link')}`;
    if (opts.renderGifThumbnail && isGif(url, opts.mimeType)) {
        cdnUrl += '@png';
    }
    return cdnUrl;
};
function isGif(url, mimeType) {
    return url.endsWith('gif') || Boolean(mimeType?.includes('gif'));
}
function isWebp(url) {
    return url.endsWith('webp');
}
function isDataUri(url) {
    return url.startsWith('data:');
}
export function isVideo(url) {
    return url.endsWith('mp4');
}
function shouldMimeTypeUseCDN(mimeType) {
    return !['gif', 'video'].some(type => mimeType.includes(type));
}
export function shouldUseCdn({ img, disableCdn, mimeType, renderGifThumbnail, }) {
    if (disableCdn) {
        return false;
    }
    if (mimeType && !shouldMimeTypeUseCDN(mimeType)) {
        return false;
    }
    // If renderGifThumbnail is set, use CDN to render the thumbnail
    if (isGif(img, mimeType) && renderGifThumbnail) {
        return true;
    }
    return (!isWebp(img) && !isGif(img, mimeType) && !isVideo(img) && !isDataUri(img));
}
