export const REVIEW_REQUEST_RESOURCE = Object.freeze([
    'AnnouncementDraft',
]);
export const REVIEW_REQUEST_STATUS = Object.freeze([
    'pending',
    'approved',
    'rejected',
    'archived',
]);
export const REVIEW_REQUEST_DEFAULT_REJECT_MESSAGE = 'We’re sorrry, we’re unable to post your announcement. Please try editing and re-submit or reach out to support@magiceden.io for details.';
/**
 * Check whether a resource is in the review process
 */
export function isInReviewPipeline(resource) {
    return resource.reviewRequest?.status === 'pending';
}
/**
 * Check whether a resource was rejected in the last review
 */
export function isRejected(resource) {
    return resource.reviewRequest?.status === 'rejected';
}
export const isSupportedReviewRequestResource = (val) => {
    return REVIEW_REQUEST_RESOURCE.includes(val);
};
